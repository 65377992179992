* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
// linear-gradient(to right, #f2709c, #ff9472)
$theme-background-color: #ed1c24;
$theme-background-color-low: #ff8b8ff0;
$theme-text-color: #ed1c24;
$background-color-text-color: #fff;

html {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}
.log_report_table {
  td {
    padding: 0px !important;
  }
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.project-desc {
  a {
    text-decoration: none;
  }
}
// .webkit-resizer {
//   width: 10px;
//   height: 10px;
// }
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.toolbar-navigation {
  background-color: #fff;
  color: #000;
}
.nav-link {
  font-family: inherit !important;
  transition: all 0.3s ease-in-out 0s;
}
.landing-box {
  background-image: url("../../public/images/46.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}
.landing-box:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(241 242 247 / 87%);
}
.project-detail {
  font-size: 14px !important;
}
.main-color-text {
  color: $theme-text-color !important;
}
.main-bg-text-color {
  color: $background-color-text-color !important;
}
.main-color-bg {
  background: $theme-background-color !important;
}

.item-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

body {
  overflow-y: auto;
  height: 100vh;
  background: #f3f3f3 !important;
  font-family: "Kanit", sans-serif !important;
}
.font-family-sidebar {
  span {
    // color: #aaaaaa;
    font-family: inherit !important;
  }
}
#root {
  height: 100%;
}
.searchbar {
  padding-top: 10px;
}

.chat_list {
  cursor: pointer;
  padding: 8px 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: 0.2s;
  .chat_name {
    font-size: 16px;
  }
  .last-message {
    font-size: 12px;
    color: #838383;
    font-weight: 400;
  }
  .chat_status {
    color: #0078a8;
    font-size: 12px;
  }
}
.chat-box {
  padding: 2px 0px 0px 8px !important;
  border-radius: 10px 0px 0px 10px;
  background-color: #fff;
}

.message_container_sender {
  display: flex;
  margin: 5px 0px;
  .message_time {
    font-size: 14px;
    color: #707070;
  }
  .message_time {
    display: none;
  }
  .message_box {
    margin-left: 5px;
    border-radius: 0px 15px 15px 15px;
    box-shadow: 0px 0px 1px #000;
    padding: 8px;
    // max-width: 75%;
    width: fit-content;
    background-color: #fff;
  }
}
.action_icon {
  // padding: 0px 10px 10px;
  color: #9a9a9a;
}
.action_icon:hover {
  color: red;
}
.chat_search_input {
  background: #ffffff !important;
  box-shadow: 0px 0px 3px #000 !important;
  border-radius: 5px !important;
  padding: 8px !important;
}

.input_message_area {
  margin: 10px 10px 20px;
  border-radius: 10px;
  border: 2px solid #9a9a9a;
  font-size: 16px;
  input {
    border: none;
    background-color: transparent;
  }
  input:focus {
    border: none;
    background-color: transparent;
  }
}

.message_container_sender:hover {
  .message_time {
    display: block;
  }
}
.message_containermessage_container_receiver {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0px;
  .message_time {
    font-size: 14px;
    color: #707070;
  }
  .message_time {
    display: none;
  }
  .message_box {
    margin-left: 5px;
    border-radius: 15px 0px 15px 15px;
    box-shadow: 0px 0px 1px #000;
    padding: 8px;
    width: fit-content;
    background-color: #c9e6fc;
  }
}
.message_containermessage_container_receiver:hover {
  .message_time {
    display: block;
  }
}

.message_area {
  height: 83vh;
  span {
    color: #a3a3a3;
    font-weight: 800;
    font-size: 22px;
  }
}

.unselect-message-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.unread-message {
  background-color: rgb(62, 217, 62);
  color: #fff;
  font-size: 14px;
  padding: 3px 8px;
  border-radius: 50%;
}

.chat_list:hover {
  background-color: #dcdcdc;
  border-right: 3px solid #e6e6e6;
}

.modal-body-height {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px !important;
  height: calc(100vh - 100px);
}
.modal-container {
  padding: 5px 20px;
  .modal-header {
    padding: 5px 0px;
    .modal-title {
    }
  }
  .modal-body {
    border-top: 1px solid #c9c9c9;
    border-bottom: 1px solid #c9c9c9;
  }
  .modal-footer {
    margin-top: 10px;
  }
}
.avatar-container {
  padding-left: 8px;
  .avatar-name {
    font-size: 20px;
  }
  .avatar-role {
    font-size: 14px;
  }
}

.display-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading-image-box {
  max-width: 800px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.validation-error-msg {
  font-size: 12px;
  color: red;
}

.set-height {
  // height: calc(100vh - 100px);
  width: 100%;
  position: relative;
  top: 50px;
}
.footer-comp {
  width: 100%;
  // position: fixed;
  bottom: 0px;
}

.auth_input {
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25) !important;
  border-radius: 9px !important;
  padding: 16px !important;
}

.form-container-box {
  background: inherit;
  max-width: 500px;
  // margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.auth-logo-container {
  max-width: 200px;

  padding: 10px 0px;
}
.auth-active-tab {
  background-color: $theme-background-color;
  color: $background-color-text-color;
}
.footer-copywrite {
  background-color: #fff;
  box-shadow: 0px 0px 5px #000;
}

.login-tab {
  font-size: 22px;
  user-select: none;
  cursor: pointer;
  padding: 10px 15px;
}
.form-box-row {
  box-shadow: 0px 0px 5px #828282;
  border-radius: 10px;
}
.form-box {
  padding: 10px 20px;
}

.login-tab:hover {
  background-color: $theme-background-color !important;
  // background-color: #ff505a;
  color: $background-color-text-color;
}
.auth-form-head {
  color: $theme-text-color;
}
.forget-password {
  color: $theme-text-color;
  cursor: pointer;
  font-size: 14px;
}

.form-control:focus,
.form-check-input:focus {
  box-shadow: none !important;
}
.text-red-color {
  color: $theme-text-color;
}
.btn-bg-color {
  background-color: $theme-background-color !important;
  color: $background-color-text-color !important;
}
.navbar-dash {
  box-shadow: 0px 0px 5px #000;
}

.navbar-avatar {
  box-shadow: 0px 0px 5px #000;
}
.main-container-pages {
  position: relative;
  top: 65px;
  overflow-y: auto;
  overflow-x: auto;
  padding: 5px 12px;
  height: calc(100vh - 110px);
}
.message-container-page {
  position: relative;
  top: 65px;
  overflow-y: auto;
  overflow-x: auto;
  height: calc(100vh - 110px);
}

.dash-toolbar {
  background-color: #fff !important;
  // background-color: #ff6b6b !important;
}

.element-center {
  display: flex;
  align-items: center;
}
.dash-footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 11;
}

.sidebar-active {
  // background: #ff1f01 !important;
  border-radius: 0px 13px 13px 0px !important;
  color: #ff1f01 !important;
}
.sidebar-listitem {
  margin: 5px 0px !important;
}
.sidebar-listitem:hover {
  // background: #ff6b6b !important;
  // background-color: none !important;
  border-radius: 0px 13px 13px 0px !important;
  color: #ff6b6b !important;
}
.tool_bar {
  box-shadow: 0px 0px 1px #000 !important;
}

// .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
//   box-shadow: 0px 0px 3px #000 !important;
// }

.drop-icon {
  color: red;
}

.card-box {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 3px 5px;
}
.img-dash-box {
  max-width: 55px;
  margin: auto;
}
.table-strip-back > tbody > tr:nth-child(odd) {
  color: inherit;
  background-color: #eeeeee;
}
.table-strip-back > tbody > tr:nth-child(even) {
  color: inherit;
}

tbody {
  color: #828282 !important;
  tr:hover {
    color: #000 !important;
  }
}

.page-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 5px 5px 5px 0px;
  overflow: auto;
  max-height: 85vh;
  box-shadow: 0px 0px 2px #000;
}
.page-container:hover {
  box-shadow: 0px 0px 4px #000;
}
.tables {
  min-width: 1440px;
  thead {
    background-color: $theme-background-color;
    color: $background-color-text-color;
  }
}

tr {
  vertical-align: middle;
}
.action-button-container {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  cursor: pointer;
  background-color: #e4e4e4;
  padding: 5px;
}
.action-button-container:hover {
  box-shadow: 0px 0px 2px #000;
}
.cursor-pointer {
  cursor: pointer;
}

.task-info-count {
  font-size: 14px;
  span {
    color: #c9c9c9;
  }
}
.auth-img-side {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeeef;
  box-shadow: 0px 0px 8px #000;
}

.auth-layout-back {
  background-image: url(../../public/images/authback.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.error-layout {
  height: 100%;
  background-color: #fff;
}
.error-img-container {
  max-width: 500px;
  margin: auto;
}
.error-content-container {
  h6 {
    font-size: 26px;
    span {
      font-size: 40px;
    }
  }
}

.task-info-count:hover {
  cursor: pointer;
  span {
    color: #000;
  }
}

.side-context-section {
  width: 100%;
}
.img-container {
  max-width: 500px;
  margin: auto;
}

.hero-head-text {
  font-size: 55px;
}
.her-sec-button {
  button {
    padding: 10px 36px 8px;
    h4 {
      font-weight: 400;
    }
  }
}
.container {
  .hero-section {
    margin: 0px 0px 16px;
  }
  .joinus-section {
    margin: 28px 0px 16px;
  }
}
.logo-container-home {
  max-width: 325px;
  margin: auto;
}
.hero-main-sec {
  position: relative;
}
.join-us-section {
  position: relative;
}
.home-obj-3 {
  position: absolute;
  top: 10px;
  right: 0px;
  max-width: 40px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-2;
  animation-timing-function: ease-in-out;
  // animation-timing-function: ease;
}
.home-obj-1 {
  position: absolute;
  top: 10px;
  max-width: 40px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-2;
  animation-timing-function: ease-in-out;
  // animation-timing-function: ease;
}
@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.home-obj-2 {
  position: absolute;
  bottom: 10px;
  right: 50%;
  max-width: 40px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-1;
  animation-timing-function: ease-in-out;
  // animation-timing-function: ease;
}
@keyframes bounce-1 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.task-info-container {
  .task-card-head {
  }
  .task-card-body {
    .task-team {
    }
    .task-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .task-progress {
    }
  }
}
.hoverClass:hover {
  box-shadow: 0px 0px 4px #000;
}
.org-card-position {
  max-width: 750px;
  margin: auto !important;
}

.image-upload-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.upload-image {
  max-width: 200px;
  margin: 10px;
}

.org-card {
  .org-card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 5px gray;
    padding: 6px 12px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .organization-radio {
  }
  .org-part {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .org-fav-logo {
    margin-right: 10px;
    max-width: 55px;
  }
  .org-card-container:hover {
    box-shadow: 0px 0px 5px #000;
  }
}
// Task Tab

.tab-head {
  font-size: 17px;
  padding: 2px 20px;
  border-radius: 50px;
  cursor: pointer;
}
.tab-head:hover {
  background-color: #ff000a33;
}
.tab-head-active {
  background-color: #ff000a80;
  // border-bottom: 3px solid #000;
}
table {
  tr:hover {
    background: #ededed !important;
    box-shadow: 0px 0px 4px #000;
  }
}
.task-table {
  min-width: 900px;
  thead {
    background-color: $theme-background-color-low;
    th {
      font-weight: 500;
      font-size: 15px;
      padding: 5px 8px;
    }
  }
}
.accordion-body {
  padding: 10px !important;
}
.task-info-Box-Pending {
  cursor: pointer;
  background-color: #bae6ff !important;
}

.task-info-Box-InProcess {
  cursor: pointer;
  background-color: #ffddc6 !important;
}

.task-info-Box-Testing {
  cursor: pointer;
  background-color: #815bdd69 !important;
}

.task-info-Box-COMPLETED {
  cursor: pointer;
  background-color: #e5f5e9 !important;
}

.task-info-Box-Backlogs {
  cursor: pointer;
  background-color: #ffc5e3 !important;
}

.task-page-head {
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
  height: calc(100vh - 180px) !important;
}

.view-toggle {
  border: 2px solid #000;
  display: flex;
  border-radius: 5px;
  div {
    svg {
      font-size: 32px;
    }
  }
  div:hover {
    background-color: #ed1c24;
  }
  .toggle-active {
    background-color: #ed1c24;
    color: #fff;
  }
}
.task-card {
  background: #fff;
  padding: 8px 8px;
  border-radius: 10px;

  .task-head {
    display: flex;
    align-items: center;
    .task-card-title {
    }
    .task-card-icon {
    }
    .task-navigate-arrow {
      display: none;
    }
  }
  .task-body {
    .task-card-desc {
      display: -webkit-box;
      max-width: 450px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: 50px;
    }
    .task-button {
      display: none;
    }
    .task-other {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.taskcard-Pending {
  border-left: 5px solid #bae6ff;
}
.taskcard-InProcess {
  border-left: 5px solid #ffddc6;
}
.taskcard-Testing {
  border-left: 5px solid #815bdd69;
}
.taskcard-COMPLETED {
  border-left: 5px solid #84ff84;
}
.taskcard-Backlogs {
  border-left: 5px solid #ffc5e3;
}

.task-kanban-title {
  font-size: 20px;
  padding: 4px 0px 5px;
  margin: 5px 0px 10px;
  font-weight: 600;
}
// .taskcard-Pending:hover {
//   background-color: #bae6ff;
// }
// .taskcard-InProcess:hover {
//   background-color: #ffddc6;
// }
// .taskcard-Testing:hover {
//   background-color: #815bdd69;
// }
// .taskcard-COMPLETED:hover {
//   background-color: #a8ffa8;
// }
// .taskcard-Backlogs:hover {
//   background-color: #ffc5e3;
// }

.task-card:hover {
  box-shadow: 0px 0px 3px #000;
  .task-head {
    .task-navigate-arrow {
      display: block;
      color: #ed1c24;
    }
  }
  cursor: pointer;
  .task-body {
    .task-button {
      display: block;
    }
    .task-other {
      display: none;
    }
  }
}

.chat-comp {
  height: calc(100vh - 7px);
}
// .chat-comp {
//   height: calc(100vh - 150px);
// }

.priority-circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 5px;
}
.priority-color-HIGH {
  background-color: #ed1c24;
}
.priority-color-MEDIUM {
  background-color: #ffff01;
}
.priority-color-LOW {
  background-color: #2afc2a;
}

.discussion-container {
  height: calc(100vh - 200px);
}

.project-color-INACTIVE {
  background-color: #fb154b;
}
.project-color-COMPLETED {
  background-color: #15fb4a;
}
.project-color-HOLD {
  background-color: #ff01fb;
}
.project-color-ACTIVE {
  background-color: #3dcbff;
}

.profile-image {
  display: flex;
  justify-content: center;
  position: relative;
  img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }
  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
}

.profile-image:hover img {
  opacity: 0.3;
}

.profile-image:hover .middle {
  opacity: 1;
}

.profile-button-container {
  padding: 5px 0px 15px;
  button {
    box-shadow: 0px 0px 2px #000;
    margin-right: 10px;
  }
}

.drop-area {
  min-height: 70vh;
  background: #e8e8e8;
  margin: 8px 5px 0px 5px;
  border-radius: 10px;
}
.drop-area-border-Active {
  border-top: 5px solid #1772a7;
}
.drop-area-border-InProcess {
  border-top: 5px solid #a64c11;
}
.drop-area-border-Testing {
  border-top: 5px solid #280b6b69;
}
.drop-area-border-COMPLETED {
  border-top: 5px solid #09ae33;
}
.drop-area-border-Backlogs {
  border-top: 5px solid #c9086c;
}

.power-logout {
  background: linear-gradient(to left, #cb2d3e, #ef473a) !important;
  color: #fff !important;
  box-shadow: 0px 0px 3px #000;
}
.power-logout:hover {
  box-shadow: 0px 0px 5px #000;
}

.task-drag-Box-Active {
  background-color: #bae6ff !important;
  border: 1px solid #1772a7;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #1772a7 !important;
}
.task-drag-Box-Pending {
  background-color: #bae6ff !important;
  border: 1px solid #1772a7;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #1772a7 !important;
}
.task-drag-Box-InProcess {
  background-color: #ffddc6 !important;
  border: 1px solid #a64c11;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #a64c11 !important;
}
.task-drag-Box-Testing {
  background-color: #815bdd69 !important;
  border: 1px solid #280b6b69;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #280b6b69 !important;
}
.task-drag-Box-COMPLETED {
  background-color: #e5f5e9 !important;
  border: 1px solid #09ae33;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #09ae33 !important;
}
.task-drag-Box-Backlogs {
  background-color: #ffc5e3 !important;
  border: 1px solid #c9086c;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #c9086c !important;
}

.priority-color-drag-HIGH {
  background-color: #ffc5e3 !important;
  border: 1px solid #c9086c;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #c9086c !important;
}
.priority-color-drag-MEDIUM {
  background-color: #ffffa4 !important;
  border: 1px solid #777703;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #777703 !important;
}
.priority-color-drag-LOW {
  background-color: #9bff9b !important;
  border: 1px solid #0a950a;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #0a950a !important;
}

.task-card-box {
  width: 100%;
  // padding: 15px 10px;
  padding: 15px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 1px #000;
  .task-navigate-drag-arrow {
    display: none;
  }
  .task-label {
    span {
      font-size: 14px;
    }
  }
  .card-title {
    font-size: 18px;
    font-weight: 600;
  }
  .card-footer {
    .task-due-date {
      font-size: 14px;
    }
  }
}

.task-card-box:hover {
  .task-navigate-drag-arrow {
    display: block;
    color: #ed1c24;
  }
}

hr {
  margin: 4px 0px !important;
}

.color-link {
  a {
    color: #fff !important;
    text-decoration: none;
  }
}

.graph-container {
  max-width: 500px;
  margin: auto;
}

.comment-box-divider {
  border-left: 1px solid #000;
}

.comment-section {
  .message-container {
    overflow-y: auto;
    height: 40vh;
    .sender-name {
    }
  }
  .message-send-container {
    padding-top: 5px;
    margin-top: 10px;
    .input-comment {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }
}

.status-color-drag-ACTIVE {
  background-color: #73c1e0 !important;
  border: 1px solid #0078a8;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #0078a8 !important;
}
.status-color-drag-COMPLETED {
  background-color: #83e671 !important;
  border: 1px solid #0a950a;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #0a950a !important;
}
.status-color-drag-HOLD {
  background-color: #c1a76b !important;
  border: 1px solid #956800;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px 10px;
  color: #956800 !important;
}

.user-profile-image-icon {
  max-width: 35px;
  cursor: pointer;
  img {
    border-radius: 50%;
  }
}

.scroll-button-slide {
  // height: 100px;
  overflow: auto;
  white-space: nowrap;
}
.scroll-button-slide::-webkit-scrollbar {
  display: none;
}
.scroll-button-slide::-webkit-scrollbar {
  width: 0 !important;
}

.add-buttons-boxes {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.add-buttons-boxes:hover {
  background-color: rgb(237 28 36);
  color: #fff;
}

.profile_name {
  font-size: 15px;
}
.task_slider {
  width: 99%;
  margin: auto;
}

.slick-slide {
  padding: 0px 6px;
}

.user-card {
  overflow: hidden;
  .image-part {
    margin-bottom: 5px;
  }
  .user-work-info {
    margin: 8px 0px;
    .count {
      font-weight: 600;
      font-size: 18px;
    }
    .title {
      font-size: 15px;
    }
  }
  .name {
    font-weight: 500;
  }
  .email {
    font-size: 15px;
    span {
      svg {
        font-size: 13px;
        color: $theme-text-color;
      }
    }
  }
  .user-button {
    margin: 12px 0px 0px;
  }
}

.card-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 10px;
  margin: 5px 5px 5px 0px;
  overflow: auto;
  box-shadow: 0px 0px 10px 0px #00000036;
}
.card-container:hover {
  background-color: #fff;
  box-shadow: 0px 0px 5px #000;
}
.star-view {
  .file-name-cont {
    .file-input {
      padding: 0px 8px 0px 5px;
      input {
        transform: scale(1.2);
      }
    }
    .file-star {
      // display: none;
      padding: 0px 0px 0px 3px;
      svg {
        font-size: 26px;
      }
    }
    .file-img {
      padding: 0px 6px 0px 3px;
      svg {
        color: $theme-text-color;
      }
    }
    .file-name {
      padding: 0px;
    }
  }
}

// .star-view:hover {
//   .file-star {
//     display: block;
//     padding: 0px 0px 0px 3px;
//   }
// }
.dropzone-scroll {
  overflow-y: auto !important;
}
.dropzone-module {
  .dzu-dropzone {
    overflow: hidden !important;
  }
}

.nav-item-tabs {
  display: flex;
  align-items: center;
  .nav-item-icon {
    padding: 6px 8px;
    svg {
      font-size: 30px;
    }
  }
  .nav-item-text {
  }
}
.files-tab {
  border: none !important;
  cursor: pointer;
  margin: 6px 0px;
  .file {
    user-select: none;
  }
}

.file-tab-active {
  background-color: $theme-background-color !important;
  border-radius: 12px !important ;
  color: #fff !important;
}
.files-tab:hover {
  background-color: $theme-background-color;
  border-radius: 12px;
  color: #fff;
}

.shared-tab-container {
  border-bottom: 2px solid #e6e6e6;
  .shared-tab {
    padding: 8px 16px;
    margin: 0px 1px;
    font-size: 18px;
    cursor: pointer;
  }
  .shared-tab:hover {
    background-color: rgb(249, 156, 156);
  }
  .shared-tab-active {
    font-weight: 600;
    background-color: rgb(249, 156, 156);
    border-bottom: 4px solid $theme-background-color !important;
    // color: #fff !important;
  }
}

.file-card {
  .file-image-part {
    max-width: 100px;
    margin: auto;
  }
  .file-details {
    margin-top: 4px;
    .name {
      span {
        svg {
          font-size: 26px;
        }
      }
    }
    .other {
      color: #939393;
      font-size: 13px;
    }
  }
}
.new-datepicker {
  width: 100%;
  .react-date-picker__wrapper {
    padding: 5px 8px;
    border-radius: 5px;
    background-color: #fff;
  }
}

.chat-box-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .time_msg {
    color: #8f8f8f;
  }
}

.chatscroll {
  height: 66vh;
  overflow: auto;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-end;
}

.message-upper-tab {
  height: 50px;
  padding: 3px 10px;
  box-shadow: 1px 1px 2px 0px #000;
}

.chat-participants {
  display: flex;
  font-size: 12px;
}

.group-modal-list {
  span {
    display: none;
  }
}
.group-modal-list:hover {
  span {
    display: block;
  }
}
